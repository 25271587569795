import styled from 'styled-components';

export const StyledCarousel = styled.div`

  float: right;
  height: 100%;
  width: 100%;

  button {
    margin: 0;
    border-radius: 0;
    display: block;
    background: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryWhite};
    border: none;
    height: 50%;

    padding: 0 0.5rem;
  }

`;