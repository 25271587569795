import styled from 'styled-components';

export const StyledProperty = styled.div`

  margin: 0 0 1rem 0;

  .infoBlock {
    background: ${({ theme }) => theme.primaryWhite};
    padding: 1rem;
  }

  .dealData {
    margin: 1em 0;
  }

  .propertyHeader {
    margin: 0 0 2rem 0;
  }

  h1 {
    margin: auto 0;
  }

  h1, h3, h4 {
    display: inline-block;
  }

  h3 {
    margin: 0;
  }

  h4 {
    text-transform: uppercase;
  }

  h6, p {
    margin-bottom: 2rem;
  }

  .titleData {
    text-align: right;
    margin 1rem 0;
  }

  .preTitle {
    margin: 0 0.5rem 0 0;
  }

  .contactColumn {
    background: ${({ theme }) => theme.primaryMediumLight}; 
    padding: 1rem;
  }

  .buttonContainer {
    text-align: center
  }

`;
