import styled from 'styled-components';

export const StyledAccordian = styled.div`
  position: relative;
  display: inline-block;
  flex-direction: column;
  justify-content: space-around;
  background: ${({ theme }) => theme.primaryWhite}; 
  border: none;
  padding: 0;
  z-index: 1;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;

  .true {
    font-weight: bold;
    border-width: 2px;
  }

  button {
    margin-bottom: 0.1rem;

    &:hover {
      border-width: 2px;
    }
  }
`;