import styled from 'styled-components';

export const StyledMenu = styled.li`

  margin: 0.5em 0;

  .active {
    border-bottom: 3px solid ${({ theme }) => theme.primaryFont};
  }

  a {
    display: block;
    padding: 0.5em 1rem;
    
  }

`;
