import styled from 'styled-components';

export const StyledMenuHorizontal = styled.nav`
  text-align: left;
  padding: 0 1rem;
  top: 0;
  left: 0;
  box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => theme.primaryWhite};

  .menuLinks {
    display: table;
    height: 80px;
  }
  
  .menuButton {
    padding: 1rem;
    display: block;
  }

  button {
    margin: 0;
  }

  li {
    display: table-cell;
    padding: 0 1rem;
    vertical-align: middle;
  }
  
  a {
    font-size: 13px;
    padding: 0 0.5rem;
    font-weight: bold;
    color: ${({ theme }) => theme.primaryDark};
    text-decoration: none;
    transition: color 0.3s linear;
    
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.5rem;
      text-align: center;
    }

  }

  a.menuItem:hover {
    border-bottom: 3px solid ${({ theme }) => theme.primaryHover};
  }
`;