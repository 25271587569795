export const theme = {
  primaryTypeface: 'Arial',
  primaryTypefaceBold: 'Arial',
  secondaryTypeface: 'Arial',
  secondaryTypefaceBold: 'Arial',
  primaryDark: process.env.GATSBY_PRIMARY_COLOUR,
  primaryLight: '#888d8e',
  primaryMediumLight: '#e4e5e6',
  primaryExtraLight: '#f1f2f2',
  primaryWhite: '#ffffff',
  primaryFont: process.env.GATSBY_PRIMARY_COLOUR,
  primaryFontLight: '#ffffff',
  primaryHover: process.env.GATSBY_PRIMARY_COLOUR,
  secondaryDark: '#00a8b4',
  mobile: '576px',
  navStyle: 'horizontal', //side or horizontal
  navBackground: '#fff',
  primaryButton: '#ffffff',
  siteWidth: '100%',
  siteMargin: '0 auto',
  breadcrumbSeperator: ">"
}