import React from "react";

import { StyledInfoTab } from "../css";

var formatCurrency = require("cre-plugin/src/components/utils/formatter").formatCurrency;
var formatCurrencyGLA = require("cre-plugin/src/components/utils/formatter").formatCurrencyGLA;
var formatGLA = require("cre-plugin/src/components/utils/formatter").formatGLA;

var priceRange = require("cre-plugin/src/components/utils/priceGlaData").priceRange;
var glaRange = require("cre-plugin/src/components/utils/priceGlaData").glaRange;

const InfoTab = ({ info, deal, type, i }) => {

  //Property tabs
  if (type === "property") {

    //To let
    if (deal === "ToLet") {
      return (

        <StyledInfoTab>

          <img style={{ height: 200, objectFit: "cover" }} src={info[0].best_image ? info[0].best_image : process.env.GATSBY_COMPANY_LOGO_SQUARE} alt={info[0].property_name} />

          <h4>{info[0].property_name}</h4>
          <h5>{info[0].property_category.replace("VacantLand", "Vacant land")} to let</h5>

          {/* {info[0].suburb} */}

          <h6>
            From: {formatCurrency(priceRange(info).priceFrom)} /month
          </h6>
          <h6>
            Price: {formatCurrencyGLA(priceRange(info).gross_price_1, priceRange(info).gross_price_2)}
          </h6>
          <h6>
            GLA: {formatGLA(glaRange(info).gla_1, glaRange(info).gla_2)}
          </h6>
          <h6>
            Units: {info.length > 1 ? info.length + ` units available` : info.length + ` unit available`}
          </h6>

        </StyledInfoTab>

      )
    }

    //For sale
    if (deal === "ForSale") {

      return (

        <StyledInfoTab>

          <img style={{ height: 200, objectFit: "cover" }} src={info.best_image ? info.best_image : process.env.GATSBY_COMPANY_LOGO_SQUARE} alt={info.property_name} />

          <h4>{info.property_name}</h4>
          <h5>{info.property_category} for sale</h5>

          {/* {info.suburb} */}

          <h6>
            Price: {formatCurrency(priceRange([info]).gross_price_1)}
          </h6>
          <h6>
            GLA: {formatGLA(glaRange([info]).gla_1)}
          </h6>
          <h6>
            Type: {info.property_type ? (info.property_type.charAt(0).toUpperCase() + info.property_type.slice(1)).replace("_", " ") : "Property for sale"} for sale
          </h6>

        </StyledInfoTab>

      )
    }
  }
}

export default InfoTab