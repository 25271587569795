import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

import { Grid21, StyledBanner } from "../css";

const BottomBanner = () => {

  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const { allContentfulSiteBottomBanner } = useStaticQuery(query)

  const data = allContentfulSiteBottomBanner.edges;

  return (

    <StyledBanner>

      <AutoplaySlider
        play={false}
        cancelOnInteraction={true} // should stop playing on user interaction
        interval={6000}
        bullets={false}
        className="banner bottom"
      >
        {
          data.map((slide, i) => {
            return (

              <div key={i}>

                <div id="bottomContent">

                  <Grid21>

                    <Img fixed={slide.node.image.fixed} alt={slide.node.title} />

                    <div className="bottomBlurb">

                      <h2>{slide.node.title}</h2>
                      <p dangerouslySetInnerHTML={{ __html: slide.node.childContentfulSiteBottomBannerBodyRichTextNode.childContentfulRichText.html }} />

                      {
                        slide.node.hasLink && slide.node.linksToExternal && (
                          <a href={slide.node.url} target="_blank" rel="noreferrer">
                            <button className="flatButton">Let's do it</button>
                          </a>
                        )
                      }

                      {
                        slide.node.hasLink && !slide.node.linksToExternal && (
                          <Link to={slide.node.url}>
                            <button className="flatButton">Let's do it</button>
                          </Link>
                        )
                      }

                    </div>

                  </Grid21>

                </div>

              </div>

            )
          })
        }

      </AutoplaySlider>

    </StyledBanner>

  )
}

export default BottomBanner

const query = graphql`
  query BottomBanner {
    allContentfulSiteBottomBanner {
      edges {
        node {
          id
          title
          url
          hasLink
          linksToExternal
          image {
            fluid(quality: 100, cropFocus: CENTER, maxWidth: 400, maxHeight: 400) {
              ...GatsbyContentfulFluid
            }
            fixed(width: 300, height: 300) {
              ...GatsbyContentfulFixed
            }
          }
          childContentfulSiteBottomBannerBodyRichTextNode {
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
  }
`