import styled from 'styled-components';

export const StyledBio = styled.div`

  max-width: 600px;
  margin: 2em auto;

  .socialIconRow {
    margin-top: 3rem;
    text-align: center;
  }

`;
