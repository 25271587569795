import styled from 'styled-components';

export const StyledPerson = styled.div`

  margin-bottom: 1rem;

  #personBlock {
    position: relative;
    bottom: 0;
    left: 0;
    background: white;
  }

`;
