import React from "react";
import { SEOFooter } from ".";

import { StyledFooter } from "./css";

import { event } from "./";

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: ""
    };
  }

  /* Here’s the juicy bit for posting the form submission */

  handleSubmit = e => {
    e.preventDefault();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .then(() => alert("Successfully subscribed!"))
      .catch(error => alert(error));

    event({
      category: 'Subscription',
      action: 'newsletter_form_submission',
      label: "new sign up"
    });

  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {

    return (

      <StyledFooter>

        <div className="wf-footer">
		<div className="container container--wide">
			<div className="wf-footer-top">
				<div className="wf-footer-cols">
					<div className="wf-footer-col wf-footer-col--2 wf-footer-col-mob--12 wf-footer-align-left">
						<h5 className="wf-footer-heading wf-footer-heading--alpha">Get the GO Waterfall App</h5>
						<ul className="x3">

							<li>
								<a href="https://play.google.com/store/apps/details?id=za.co.thinkdigital.gowaterfall&amp;hl=en_ZA">
									<span className="btn btn--appstore btn--appstore:md--wide">
										<span className="btn--appstore-icon"> <img
												src="https://waterfallcity.co.za/wp-content/uploads/2020/06/playstore.svg" /> </span>
										<span className="btn--appstore-text">
											<div className="btn--appstore-text-sm"> Download from </div>
											<div className="btn--appstore-text-lg"> Google Play </div>
										</span>
									</span> </a>
							</li>

							<li>
								<a href="https://apps.apple.com/za/usr/www/users/waterk/go-waterfall/id1282307656">
									<span className="btn btn--appstore btn--appstore:md--wide">
										<span className="btn--appstore-icon"> <img
												src="https://waterfallcity.co.za/wp-content/uploads/2020/06/apple.svg" /> </span>
										<span className="btn--appstore-text">
											<div className="btn--appstore-text-sm"> Download on the </div>
											<div className="btn--appstore-text-lg"> APP STORE </div>
										</span>
									</span> </a>
							</li>

						</ul>
					</div>
					<div className="wf-footer-col wf-footer-col--2 wf-footer-col-mob--6 wf-footer-align-left">
						<h5 className="wf-footer-heading wf-footer-heading--alpha">Easy Access</h5>
						<ul className="">

							<li>
								<a href="https://waterfall.communityportal.co.za/login" target="_blank"> Residents Portal</a>
							</li>

							<li>
								<a href="https://attacq.procure.co.za" target="_blank"> Procurement Portal</a>
							</li>
						</ul>
					</div>
					<div className="wf-footer-col wf-footer-col--4 wf-footer-col-mob--6 wf-footer-align-left">
						<h5 className="wf-footer-heading wf-footer-heading--alpha">Follow Waterfall City</h5>
						<ul className="wf-cols2">

							<li>
								<a href="https://waterfallcity.co.za/news-cat/magazine/" target="_self"> Magazine</a>
							</li>

							<li>
								<a href="https://waterfallcity.co.za/news-cat/podcasts/" target="_self"> Podcast</a>
							</li>

							<li>
								<a href="https://waterfallcity.co.za/news/?wf-filter=yes&amp;wf-news-filters%5Bcat%5D%5B%5D=9"
									target="_self"> Newsletter</a>
							</li>

							<li>
								<a href="https://waterfallcity.co.za/news/" target="_self"> What’s News?</a>
							</li>

							<li>
								<a href="https://waterfallcity.co.za/events/" target="_self"> What’s Happening?</a>
							</li>

							<li>
								<a href="https://waterfallcity.co.za/waterfall-awards/" target="_self"> Awards</a>
							</li>

							<li>
								<a href="https://waterfallcity.co.za/gallery/" target="_self"> Gallery</a>
							</li>
						</ul>
					</div>
					<div className="wf-footer-col wf-footer-col--4 wf-footer-col-mob--12 wf-footer-align-right">
						<h5 className="wf-footer-heading wf-footer-heading--beta">Sign up to The Source Newsletter. Powered by
							Mailchimp.</h5>
						<div className="wf-footer-newsletter">
							<form action="" className="wf-newsleter">
								<div className="wf-footer-newsletter-fields">
									<div className="wf-footer-newsletter-field wf-footer-newsletter-field--name">
										<input type="text" name="name" placeholder="Your name" />
									</div>
									<div className="wf-footer-newsletter-field wf-footer-newsletter-field--email">
										<input type="text" name="email" placeholder="Email address" />
									</div>
									<div className="wf-footer-newsletter-btn">
										<button className="btnwrap">
											<span className="btn btn--solid btn--sm ">
												<span>Sign up</span>
											</span> </button>
									</div>
									<span className="wf-footer-newsletter__message">Thank you</span>
								</div>
							</form>
						</div>
						<div className="wf-footer-social">
							<ul className="wf-footer-social__links">

								<li>
									<a href="https://www.facebook.com/WaterfallCityDevelopment/">
										<img src="https://waterfallcity.co.za/wp-content/uploads/2020/06/Path-35.svg" alt="Facebook" />
									</a>
								</li>

								<li>
									<a href="https://www.instagram.com/waterfallcity/">
										<img src="https://waterfallcity.co.za/wp-content/uploads/2020/06/Group-303.svg" alt="Instagram" />
									</a>
								</li>

								<li>
									<a href="https://twitter.com/WaterfallCityZA">
										<img src="https://waterfallcity.co.za/wp-content/uploads/2020/06/Group-305.svg" alt="Twitter" />
									</a>
								</li>
							</ul>

							<ul className="wf-footer-social__btns">
								<li>
									<a href="https://waterfallcity.co.za/contact/">
										<span className="btn btn--size-sm">
											<span>Contact waterfall City</span>
										</span>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

			</div>
			<div className="wf-footer-bottom">
				<div className="wf-footer-bottom-main">
					<div className="wf-footer-bottom-links">
						<li className="wf-footer-bottom-copyright">
							© AWIC Waterfall TM JVCO (RF) (Pty) Ltd. 2022 </li>
						<li><a href="https://waterfallcity.co.za/terms-and-conditions"> Ts &amp; Cs </a></li>
						<li><a href="https://waterfallcity.co.za/privacy-policy"> Privacy Policy </a></li>
						<li><a href="https://waterfallcity.co.za/wp-content/uploads/Final-AWIC-PAIA-Manual-V2-14.09.2022.docx.pdf">
								PAIA Manual </a></li>
					</div>
					<div className="wf-footer-share">
						<a href="#" className="clipboard-share" data-clipboard-text="https://waterfallcity.co.za/">
							<span>Share website</span>
							<svg xmlns="http://www.w3.org/2000/svg" height="20.396" viewBox="0 0 20.396 20.396" width="20.396">
								<path
									d="M204.994,0a4.849,4.849,0,0,0-3.451,1.43l-3.8,3.8a5.767,5.767,0,0,1,3.236.369l2.367-2.367a2.331,2.331,0,1,1,3.3,3.3l-4.261,4.261a2.388,2.388,0,0,1-3.3,0l-1.8,1.8a4.88,4.88,0,0,0,6.9,0l4.261-4.261A4.88,4.88,0,0,0,204.994,0Z"
									data-name="Path 22908" fill="currentColor" transform="translate(-189.478)"></path>
								<path
									d="M8.874,170.7l-2.345,2.345a2.331,2.331,0,1,1-3.3-3.3l4.261-4.261a2.388,2.388,0,0,1,3.3,0l1.8-1.8a5,5,0,0,0-6.9,0L1.43,167.943a4.88,4.88,0,1,0,6.9,6.9l3.75-3.75a6.178,6.178,0,0,1-.827.081A6.142,6.142,0,0,1,8.874,170.7Z"
									data-name="Path 22909" fill="currentColor" transform="translate(0 -155.879)"></path>
							</svg> <span className="wf-notify">Copied to clipboard</span>
						</a>
					</div>
				</div>
				<div className="wf-footer-bottom-extra">
					<p>Attacq Management Services Proprietary Limited, a wholly owned subsidiary of Attacq Limited, is registered
						with the PPRA and holds a fidelity fund certificate and trust account in terms of the Property Practitioners
						Act 22 of 2019.</p>
				</div>
			</div>
		</div>
	</div>

        <SEOFooter />

      </StyledFooter >

    )
  }
}

export default Footer