import { createGlobalStyle } from 'styled-components';
import 'react-awesome-slider/dist/styles.css';

export const GlobalStyles = createGlobalStyle`

    @font-face {
      font-family: "GothamPro-Custom";
      src: url("/fonts/gotham-pro/GothamPro.woff");
    }
    html {
      margin: 0;
      padding: 0;
      color: ${({ theme }) => theme.primaryFont};
      
    }

    .pageContainer {
      padding: 0 40px;
      max-width: ${({ theme }) => theme.siteWidth};
      margin: ${({ theme }) => theme.siteMargin};
    }

    body {
      background-color: ${({ theme }) => theme.primaryExtraLight};
      margin: 0;
      width: 100%;
      justify-content: center;
      text-rendering: optimizeLegibility;
    }

    *, *::after, *::before {
      box-sizing: border-box;
    }

    textarea, input, span, .input-title {
      font-family: ${({ theme }) => theme.primaryTypeface}, sans-serif;
      font-size: 1rem;
    }

    div#___gatsby, div#gatsby-focus-wrapper {
      min-height: 100vh;
    }
    
    a {
      text-decoration: none;
      cursor: pointer;
      color: ${({ theme }) => theme.primaryFont};
      font-family: ${({ theme }) => theme.secondaryTypeface};
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: ${({ theme }) => theme.secondaryTypefaceBold}, sans-serif;
    }

    h1 {
      font-size: 2.25rem;
      text-transform: uppercase;
      font-weight: bold;
    }

    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.75rem;
      margin: 1em 0;
      color: ${({ theme }) => theme.primaryLight};
      
    }

    h4 {
      font-size: 1.5rem;

    }

    h5 {
      font-size: 1.25rem;
      
    }

    h6 {
      font-size: 1rem;
      margin: 3px 0;
    }

    p, input, textarea, td {
      font-family: ${({ theme }) => theme.primaryTypeface}, sans-serif;
    }

    p {
      font-size: 1rem;
      margin: 10px 0;
    }

    .md-form {
      margin: 0;
    }

    button, .button {
      width: auto;
      font-family: ${({ theme }) => theme.primaryTypeface}, sans-serif;
      font-size: 0.8rem;
      color: ${({ theme }) => theme.primaryDark};
      border: 1px solid ${({ theme }) => theme.primaryDark};
      padding: 1em 2.5rem;
      margin: 0 1em 1em 0;
      background-color: ${({ theme }) => theme.primaryButton};
      cursor: pointer;
      text-transform: uppercase;
    }

    .flatButton {
      font-family: ${({ theme }) => theme.primaryTypeface}, sans-serif;
      background-color: transparent;
      color: ${({ theme }) => theme.primaryFont};
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 1px solid ${({ theme }) => theme.primaryDark};
      width: 100%;
      text-align: left;
      padding: 10px 0;
      border-radius: 0;

      &:hover {
        font-weight: bold;
      }
    }

    .socialIcon svg {
      width: 1rem;
      height: 1rem;
      margin: 1rem;
      &:hover {
        color: ${({ theme }) => theme.primaryDark};
      }
    }

    blockquote {
      background: ${({ theme }) => theme.primaryLight};
      border-left: 10px solid ${({ theme }) => theme.primaryLight};
      quotes: "\201C""\201D""\2018""\2019";
    }
    
    blockquote:before {
      color: ${({ theme }) => theme.primaryDark};
      content: open-quote;
      line-height: 0.1rem;
      margin-right: 0.25rem;
      vertical-align: -0.4rem;
    }

    blockquote p {
      display: inline;
    }


    //media

    @media(max-width:576px){
      .pageContainer {
        padding: 0 20px;
      }
    }	
    
    @media(min-width: 577px) and (max-width: 768px){
      .pageContainer {
        padding: 0 30px; 
      }
    }	
    
    @media(min-width: 769px) and (max-width:992px) {
      
    }	
    
    @media(min-width: 993px){
      
    }

  `