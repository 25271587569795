import React, { Fragment, useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import { StyledImage } from "../css";

//Loading

function OnImageLoad() {

  const [isFetching, setFetching] = useState(true);

  useEffect(() => {
    setFetching(false)
  }, []);
  return {
    fetching: isFetching
  }
}

//Custom controls

// function renderLeftNav(onClick, disabled) {
//   return (

//     <button
//       className='buttonNav left'
//       disabled={disabled}
//       onClick={onClick}>

//       {`<`}

//     </button>

//   )
// }

// function renderRightNav(onClick, disabled) {
//   return (

//     <button
//       className='buttonNav right'
//       disabled={disabled}
//       onClick={onClick}>

//       {`>`}

//     </button>

//   )
// }

const ImageShow = ({ data, title, type, open }) => {

  var imageArray = [];

  if (data.length > 0) {

    data.forEach((image) => {
      imageArray.push({
        thumbnail: image.image_path_url,
        original: image.image_path_url.replace("h_600,w_600", "h_800,w_1000,f_auto"),
        originalAlt: title,
        thumbnailAlt: title
      })
    })

    var { fetching } = OnImageLoad();

  }

  return (

    <Fragment>

      {
        fetching && type === "exterior" && imageArray.length !== 0 && open === 0 && typeof window !== 'undefined' && (
          <StyledImage>
            <div className="loader"></div>
          </StyledImage>
        )
      }

      {
        !fetching && type === "exterior" && imageArray.length !== 0 && open === 0 && typeof window !== 'undefined' && (
          <StyledImage>
            <ImageGallery
              items={imageArray}
              lazyLoad={false}
              showBullets={false}
              showFullscreenButton={true}
              showPlayButton={false}
              showThumbnails={true}
              showNav={true}
              thumbnailPosition="bottom"
              // renderLeftNav={renderLeftNav}
              // renderRightNav={renderRightNav}
            />
          </StyledImage>
        )
      }

      {
        fetching && type === "interior" && imageArray.length !== 0 && open === 1 && typeof window !== 'undefined' && (
          <StyledImage>
            <div className="loader"></div>
          </StyledImage>
        )
      }

      {
        !fetching && type === "interior" && imageArray.length !== 0 && open === 1 && typeof window !== 'undefined' && (
          <StyledImage>
            <ImageGallery
              items={imageArray}
              lazyLoad={false}
              showBullets={false}
              showFullscreenButton={true}
              showPlayButton={false}
              showThumbnails={true}
              showNav={true}
              thumbnailPosition="bottom"
              // renderLeftNav={renderLeftNav}
              // renderRightNav={renderRightNav}
            />
          </StyledImage>
        )
      }

      {
        fetching && type === "floor_plan" && imageArray.length !== 0 && open === 2 && typeof window !== 'undefined' && (
          <StyledImage>
            <div className="loader"></div>
          </StyledImage>
        )
      }

      {
        !fetching && type === "floor_plan" && imageArray.length !== 0 && open === 2 && typeof window !== 'undefined' && (
          <StyledImage>
            <ImageGallery
              items={imageArray}
              lazyLoad={false}
              showBullets={false}
              showFullscreenButton={true}
              showPlayButton={false}
              showThumbnails={true}
              showNav={true}
              thumbnailPosition="bottom"
              // renderLeftNav={renderLeftNav}
              // renderRightNav={renderRightNav}
            />
          </StyledImage>
        )
      }

    </Fragment>

  )
}

export default ImageShow