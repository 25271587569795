import React, { Fragment, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import { StyledVideo } from "../css";

//Loading

function OnVideoLoad() {

  const [isFetching, setFetching] = useState(true);

  useEffect(() => {
    setFetching(false)
  }, []);
  return {
    fetching: isFetching
  }
}

function VideoShow({ data, show, open }){

  const { fetching } = OnVideoLoad();

  return (

    <Fragment>

      {
        show && open === 3 && (
          <div className='player-wrapper'>

            {
              !fetching && typeof window !== 'undefined' && (
                <StyledVideo>
                  <ReactPlayer
                    className='react-player'
                    url={`'https://www.youtube.com/watch?v=${data}`}
                    width='100%'
                    height='100%'
                  />
                </StyledVideo>
              )
            }

            {
              fetching && (
                <StyledVideo>
                  <div className="loader"></div>
                </StyledVideo>
              )
            }

          </div>
        )
      }

    </Fragment>

  )

}

export default VideoShow