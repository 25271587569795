import styled from 'styled-components';

export const StyledBreadcrumb = styled.div`

margin-bottom: 1rem;
  
  .breadLine {
    max-width: ${({ theme }) => theme.siteWidth};
  }

  ul {
    list-style: none;
  }

  li {
    display: inline-block;
    padding: 0 0.5rem 0 0;
    color: ${({ theme }) => theme.primaryDark};
  }

  a {
    color: ${({ theme }) => theme.primaryDark};
    &:hover {
      color: ${({ theme }) => theme.primaryLight};
    }
  }

  .active {
    color: ${({ theme }) => theme.primaryLight};
  }

`;
