import React, { Fragment } from "react";
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../components/css/global';
import { theme } from '../components/css/theme';

import { Header, Breadcrumb, Footer } from ".";

export default function Container({ children, page = "content", breadcrumbs = false, breadData }) {

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <GlobalStyles />
        <Header page={page} />
        <div className="pageContainer">
          <Breadcrumb show={breadcrumbs} data={breadData} />
          {children}
        </div>
        <Footer />
      </Fragment>
    </ThemeProvider>
  )
}