import styled from 'styled-components';

export const StyledTable = styled.div`
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    overflow: auto;

  h5 {
    margin-bottom: 1em;
  }

  table {
    max-height: 30%;
    overflow: auto;
    display: table;
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    padding: 5px 10px 5px 0;
    text-align: center;
    white-space: nowrap;
  }

  thead {
    background-color: ${({ theme }) => theme.primaryMediumLight};
    font-family: ${({ theme }) => theme.primaryTypefaceBold};
  }

`;
