import React from "react";

const SEOFooter = () => {

  //Footer
  var jsonld = {
    __html: `{ 
      "@context": "https://schema.org",
      "@type": "${process.env.GATSBY_SCHEMA_TYPE}",
      "image": [
        "${process.env.GATSBY_COMPANY_LOGO_SQUARE}",
        "${process.env.GATSBY_COMPANY_LOGO_LONG}"
      ],
      "@id": "${process.env.GATSBY_WEBSITE_URL}",
      "name": "${process.env.GATSBY_COMPANY_NAME}",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "${process.env.GATSBY_COMPANY_ADDRESS}",
        "addressLocality": "${process.env.GATSBY_COMPANY_SUBURB}",
        "addressRegion": "${process.env.GATSBY_COMPANY_PROVINCE}",
        "postalCode": "${process.env.GATSBY_COMPANY_POSTAL_CODE}",
        "addressCountry": "${process.env.GATSBY_COMPANY_COUNTRY}"
      },
      "latitude": ${process.env.GATSBY_COMPANY_LATITUDE},
      "longitude": ${process.env.GATSBY_COMPANY_LONGITUDE},
      "url": "${process.env.GATSBY_WEBSITE_URL}",
      "email": "${process.env.GATSBY_COMPANY_EMAIL}",
      "telephone": "${process.env.GATSBY_COMPANY_TEL_1}",
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday"
          ],
          "opens": "08:00",
          "closes": "17:00"
        }
      ]
    }
    {
      "@type": "WebSite",
      "name": "${process.env.GATSBY_COMPANY_NAME}",
      "url": "${process.env.GATSBY_WEBSITE_URL}",
      "sameAs": [
        "${process.env.GATSBY_FACEBOOK_PAGE}",
        "${process.env.GATSBY_TWITTER_PAGE}",
        "${process.env.GATSBY_LINKEDIN_PAGE}",
        "${process.env.GATSBY_INSTAGRAM_PAGE}"
      ],
      "potentialAction": {
        "@type": "SearchAction",
        "target": "${process.env.GATSBY_WEBSITE_URL}/app/search?page=1&query={search_term}&sortBy=ToLet",
        "query-input": "required name=search_term"
      }
    }`
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={jsonld}
    />
  )

}

export default SEOFooter;