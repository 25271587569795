import styled from 'styled-components';

export const StyledLink = styled.li`

  list-style: none;
  text-align: left;
  font-weight: normal;
  font-size: 1.5rem;

  a {
    color: ${({ theme }) => theme.primaryFont};
  }

`;
