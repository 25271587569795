import styled from 'styled-components';

export const StyledVideo = styled.div`

  margin-bottom: 4rem;

  .react-player {
    height: 500px!important;
  }
  
`