import styled from 'styled-components';

export const StyledForm = styled.div`

  padding: 2rem;

  //media

  @media(max-width:576px){
    padding: 1rem;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    padding: 1rem;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    padding: 1rem;
  }	
  
  @media(min-width: 993px) and (max-width:1024px){
    padding: 1rem;
  }

  span {
    color: ${({ theme }) => theme.primaryDark};
  }

  .contactDetails {
    padding: 0 1rem;
  }

  .contactDetails p {
    margin: 3px 0;
  }

  .contactDetails h5 {
    margin: 0 0 0.5rem 0;
  }

  .contactDetails > * {
    vertical-align: bottom;
  }

  form {
    margin-top: 4rem;
  }

  input, textarea {
    background-color: ${({ theme }) => theme.primaryWhite};
    color: ${({ theme }) => theme.primaryDark};
    display: block;
    border: none;
    padding: 0.5rem;
    width: 100%;
    margin: 1rem 0;
  }


  img {
    border-radius: 1rem;
    max-height: 150px;
    width: auto!important;
    max-width: 100%;
  }
`;