import React, { Fragment } from "react";

import { Link } from "gatsby";

import { theme } from '../css/theme';
import { StyledBreadcrumb } from "../css";

const Seperator = () => (

  <li>
    <h6>{theme.breadcrumbSeperator}</h6>
  </li>

)

const Breadcrumbs = ({ show, data }) => {

  if (show) {

    if (data.type === "content") {
      //Regular site pages

      return (

        <StyledBreadcrumb>

          <div className="breadLine">

            <li>
              <Link to={`/`} >
                <h6>Work</h6>
              </Link>
            </li>

            {
              data.mainCat && (

                <Fragment>

                  <Seperator />

                  <li>
                    <Link to={`/${data.mainCat}`} >
                      <h6>{data.mainCat}</h6>
                    </Link>
                  </li>


                </Fragment>

              )
            }


            {
              data.secondaryCat && (

                <Fragment>

                  <Seperator />

                  <li className="active">
                    <Link to={`/${data.mainCat}/${data.secondaryCat}`} >
                      <h6>{data.secondaryCat}</h6>
                    </Link>
                  </li>

                </Fragment>

              )
            }

            {
              data.title && (

                <Fragment>

                  <Seperator />

                  <li className="active">
                    <h6>{data.title}</h6>
                  </li>

                </Fragment>

              )
            }

          </div>

        </StyledBreadcrumb>
      )

    }

    if (data.type === "property") {

      //Property pages

      return (

        <StyledBreadcrumb>

          <div className="breadLine">

            <li>
              <Link to={`/app/search?page=1`} >
                <h6>Work</h6>
              </Link>
            </li>

            <Seperator />

            <li>
              <Link to={`/app/search?page=1&sortBy=${data.deal}`} >
                <h6>{data.deal.replace("ToLet", "To let").replace("ForSale", "For sale")}</h6>
              </Link>
            </li>

            <Seperator />

            <li>
              <Link to={`/app/search?page=1&sortBy=${data.deal}&query=&refinementList%5Bproperty_category%5D%5B0%5D=${data.data.property_category}`} >
                <h6>{data.data.property_category.replace("VacantLand", "Vacant land")}</h6>
              </Link>
            </li>

            <Seperator />

            <li className="active">
              <Link to={`/app/search?page=1&sortBy=${data.deal}&query=&refinementList%5Bsuburb%5D%5B0%5D=${data.data.suburb}&refinementList%5Bproperty_category%5D%5B0%5D=${data.data.property_category}`} >
                <h6>{data.data.suburb}</h6>
              </Link>
            </li>

            <Seperator />

            <li className="active">
              <h6>{data.data.property_name}</h6>
            </li>

          </div>

        </StyledBreadcrumb>
      )

    }

    if (data.type === "search") {
      //Search page

      return (

        <StyledBreadcrumb>

          <div className="breadLine">

            <li>
              <h6>Work</h6>
            </li>

            <Seperator />

            <li>
              <h6>Search Available Space</h6>
            </li>

          </div>

        </StyledBreadcrumb>
      )

    }

  } else {

    return false;

  }
}

export default Breadcrumbs;