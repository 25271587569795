import styled from 'styled-components';

export const StyledQuickSearch = styled.div`

  max-width: 400px;
  max-height: 300px;
  overflow-y: auto;
  margin: 0;
  background-color: white;
  padding: 1rem 1rem 0 1rem;

  .quickSection {
    margin-bottom: 1rem;
  }

  .ais-Hits-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  p {
    margin: 0;
  }

`;
