import styled from 'styled-components';

export const StyledPagination = styled.ul`

  margin-top: 2rem;
  color: ${({ theme }) => theme.primaryFont};
  list-style: none;
  text-align: center;
  padding: 0;

  li {
    display: inline-block;
    margin: 1rem;
  }

`;
