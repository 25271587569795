import styled from 'styled-components';

export const StyledInput = styled.div`

  div {
    background-color: transparent;
    border: none;
  }

  span {
    color: ${({theme}) => theme.primaryDark};
  }
  
  label {
    display: block;
    text-align: center;
    margin-bottom: 5px;
  }

  input, textarea {
    border-color: hsl(0,0%,80%);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    padding: 0.5rem;
    width: 100%;
    margin-bottom: 1rem;
  }

  i {
    display: none;
  }
`;