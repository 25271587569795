import styled from 'styled-components';

export const StyledBanner = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 500px;
  background: transparent;

  .banner {
    --content-background-color: transparent;
    z-index: 0;
  }

  .banner.top {
    height: 100%;
  }

  .awssld__container {
    padding-bottom: 0;
    height: 100%;
  }

  img {
    object-fit: contain!important;
  }

  #bannerBlock {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 400px;
    z-index: 2;
  }

  h1 {
    font-size: 1.5rem;
    margin: 0;
    color: ${({theme}) => theme.primaryLight};
  }

  h2 {
    font-size: 1.5rem;
    margin: 0;
    color: ${({theme}) => theme.primaryDark};
  }

  button {
    margin: 0;
  }

  nav.awssld__bullets button {
    margin: 0.3rem;
  }

  .bottomBlurb {
    max-width: 90%;
  }

  .banner.top .gatsby-image-wrapper {
    max-width: 900px;
    margin: 0 auto;
  }

  .banner.bottom .awssld__wrapper, banner.reviews .awssld__wrapper {
    height: 300px;
  }

  .banner.bottom, banner.reviews {
    max-width: 1200px;
    margin: 0 auto;
  }

  .banner.bottom #bottomContent {
    max-width: 900px;
    margin: 0 auto;
  }

  .banner.bottom .flatButton {
    position: absolute;
    bottom: 0;
    max-width: 300px;
  }

  .awssld.reviews {
    --content-background-color: ${({theme}) => theme.primaryLight};
    height: 300px;
  }

  .reviews .awssld__content {
    display: flex;
  }

  .reviews .awssld__content div {
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;
  }

  nav.awssld__bullets {
    bottom: 1rem;
  }

  button.awssld__bullets--active {
    background: ${({theme}) => theme.primaryDark}!important;
  }

  .awssld__content {
    display: inherit;
  }

  .awssld__content div {
    width: 100%;
    z-index: 1;
  }

  .awssld__bullets .awssld__bullets--active {
    transform: scale(1.0);
    opacity: 1;
  }

  .awssld__controls__arrow-left, .awssld__controls__arrow-right {
    height: 1rem;
  }

  .awssld__controls__arrow-left:after, .awssld__controls__arrow-right:after, .awssld__controls__arrow-left:before, .awssld__controls__arrow-right:before {
    max-height: 1rem;
  }

  .awssld__bullets button {
    height: 10px;
    width: 10px;
    background: ${({theme}) => theme.primaryWhite};
    opacity: 0.7;
  }

  .awssld__timer.awssld__timer--animated.awssld__timer--run {
    display: none;
  }

  nav.awssld__bullets {
    z-index: 5;
  }

  .loader {
    border: 5px solid ${({ theme }) => theme.primaryLight}; /* Light grey */
    border-top: 5px solid ${({ theme }) => theme.primaryDark}; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: 1rem auto;
  }

  @media(max-width:576px){
    height: 300px;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    height: 300px;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    height: 300px;
  }	
  
  @media(min-width: 993px){
    height: 500px;
  }

`;