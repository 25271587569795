import styled from 'styled-components';

//20 52 26
export const Grid1 = styled.div`
  display: grid;
  max-width: 900px;
  grid-column-gap: 5px;

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 100%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 100%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 20% 50% 26%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }
`;

//Half
export const Grid2 = styled.div`
  display: grid;
  max-width: 100%;

  .flatGrid {
    background-size: cover; 
    background-position: center;
    position: relative; 
  }

  @media(max-width:576px){
    grid-template-columns: 100%;
    .infoBlock {
      order: 2;
    }
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;

    .infoBlock {
      order: 2;
    }
  }	
  
  @media(min-width: 769px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	

  img {
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

export const Grid21 = styled.div`
  display: grid;
  max-width: 100%;
  width: 100%;
  height: 100%;

  @media(max-width:576px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	
  
  @media(min-width: 769px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	

  img {
    width: 50%;
    object-fit:contain;
    object-position: top;
  }
`;

export const Grid22 = styled.div`
  display: grid;
  max-width: 100%;
  width: 100%;

  @media(max-width:576px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 0.5rem;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 0.5rem;
  }	
  
  @media(min-width: 769px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 0.5rem;
  }	

  img {
    width: 100%;
  }
  
`;

export const Grid23 = styled.div`
  display: grid;
  max-width: 100%;

  grid-template-columns: 30% 70%;

  img {
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

//Thirds
export const Grid3 = styled.div`
  display: grid;
  max-width: 100%;
  width: 100%;

  grid-template-columns: 32% 32% 32%;
  grid-column-gap: 2%;

  .flatGrid {
    background-size: cover; 
    background-position: center;
    position: relative; 
    height: 100%;
  }

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 1rem;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 1rem;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 1rem;
  }	
  
  @media(min-width: 993px) and (max-width:1024px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 1rem;
  }

`;

export const Grid31 = styled.div`
  
  display: grid;
  max-width: 100%;

  .staggeredGrid {
    background-size: cover; 
    background-position: center;
    position: relative; 
    height: 400px;
  }


  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
  }

  img {
    width: 100%;
    object-fit:contain;
    object-position: top;
    height: 300px;
  }
`;

export const Grid32 = styled.div`
  display: grid;
  max-width: 100%;

  @media(max-width:576px){
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 2%;
    grid-row-gap: 4%;
  }

  img {
    width: 100%;
    object-fit:contain;
    object-position: top;
    height: 200px;
  }
`;

export const Grid33 = styled.div`
  display: grid;
  max-width: 100%;

  @media(max-width:576px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 34% 64%;
    grid-column-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 34% 64%;
    grid-column-gap: 2%;
  }
`;

export const Grid34 = styled.div`
  display: grid;
  max-width: 100%;
  width: 100%;

  @media(max-width:576px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 64% 34%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 64% 34%;
    grid-column-gap: 2%;
    grid-row-gap: 4%;
  }
`;

export const Grid35 = styled.div`
  display: grid;
  max-width: 100%;
  width: 100%;

  @media(max-width:576px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 50% 23% 23%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 30% 28% 38%;
    grid-column-gap: 2%;
    grid-row-gap: 4%;
  }

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 300px;
  }
`;

export const Grid36 = styled.div`
  display: grid;
  max-width: 100%;
  width: 100%;

  @media(max-width:576px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 64% 34%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 64% 34%;
    grid-column-gap: 2%;
    grid-row-gap: 4%;
  }
`;

export const Grid37 = styled.div`
  display: grid;
  max-width: 100%;

  @media(max-width:576px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 100%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 34% 64%;
    grid-column-gap: 2%;
  }
`;

//Quarters
export const Grid4 = styled.div`
  display: grid;
  max-width: 900px;

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
    grid-column-gap: 2%;
    grid-row-gap: 1%;
  }

  img {
    object-fit:contain;
    object-position: top;
    height: 300px;
  }
`;

export const Grid41 = styled.div`
  display: grid;
  max-width: 100%;

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
    grid-column-gap: 2%;
    grid-row-gap: 1%;
  }

  img {
    object-position: top;
    height: 300px;
  }
`;

//
export const Grid5 = styled.div`
  display: grid;
  max-width: 900px;

  @media(max-width:576px){
    grid-template-columns: 100%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 769px) and (max-width:992px) {
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 2%;
  }	
  
  @media(min-width: 993px){
    grid-template-columns: 15% 39% 30% 10%;
    grid-column-gap: 2%;
    grid-row-gap: 1%;
  }

  img {
    object-fit:contain;
    object-position: top;
    height: 300px;
  }
`;

export const Grid6 = styled.div`
  display: grid;
  max-width: 900px;
  padding: 0;
  width: 90%;
  position: absolute;
  bottom: 0;
  left: 0;
  grid-template-columns: 90% 10%;

`;

export const Grid61 = styled.div`
  display: grid;
  grid-template-columns: 15% 84%;
  grid-column-gap: 1%;
  grid-row-gap: 1%;

  .itemCount {
    font-style: italic;
    display: inline-block;
    position: relative;
    font-weight: normal;
    font-size: 1rem;
  }

  .itemCount:after {
    content:"";
    position:absolute;
    top: 10px;
    right: -35px;
    width: 40px;
    transform: rotate(135deg);
    transform-origin: 0% 0%;
    border-top: 1px solid orange;
  }

`;

export const Grid62 = styled.div`
  display: grid;
  grid-template-columns: 25% 64%;
  grid-column-gap: 1%;

`;