import styled from 'styled-components';

export const StyledInfoTab = styled.div`

margin-bottom: 2rem;

  h4 {
    margin: 1rem 0;
    text-transform: uppercase;
  }

  h5 {
    color: ${({ theme }) => theme.primaryDark};
    margin: 1rem 0;
    font-weight: bold;
    text-transform: uppercase;
  }

  h6 {
    color: ${({ theme }) => theme.primaryDark};
    margin: 0.5rem 0;
    font-weight: bold;
  }

  p {
    margin: 2px;
    display: inline-block;
    color: ${({ theme }) => theme.primaryLight};
  }

  p:first-letter { text-transform: uppercase;}

  span {
    display: block;
  }

  img {
    width: 100%;
  }

`;
