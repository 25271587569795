import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

import { StyledBanner, StyledBasicTab, Grid6, Grid61, StyledCarousel } from "../css";

const zeroPad = (num, places) => String(num).padStart(places, '0');

const TopBanner = () => {

  // const AutoplaySlider = withAutoplay(AwesomeSlider);

  // const { allContentfulSiteBanner } = useStaticQuery(query)

  // const data = allContentfulSiteBanner.edges;

  return (

    <StyledBanner>

      {/* <AutoplaySlider
        play={true}
        cancelOnInteraction={true} // should stop playing on user interaction
        interval={6000}
        bullets={false}
        className="banner top"
      >
        {
          data.map((slide, i) => {
            return (
              <div key={i} >
                <Img fluid={slide.node.image.fluid} alt={slide.node.title} />

                <div id="bannerBlock">

                  <Grid6>

                    <StyledBasicTab >

                      <Grid61>

                        <p className="itemCount">{zeroPad(i + 1, 2)}</p>

                        <div>

                          <h6>{slide.node.tags}</h6>
                          <h5>{slide.node.title}</h5>

                        </div>

                      </Grid61>


                    </StyledBasicTab>

                    <StyledCarousel>

                      {
                        slide.node.hasLink && slide.node.linksToExternal && (
                          <a href={slide.node.url} target="_blank" rel="noreferrer">
                            <button style={{ height: "100%", width: "100%" }}>{`>`}</button>
                          </a>
                        )
                      }

                      {
                        slide.node.hasLink && !slide.node.linksToExternal && (
                          <Link to={slide.node.url}>
                            <button style={{ height: "100%", width: "100%" }}>{`>`}</button>
                          </Link>
                        )
                      }

                    </StyledCarousel>

                  </Grid6>

                </div>

              </div>
            )
          })
        }

      </AutoplaySlider> */}

    </StyledBanner>

  )
}

export default TopBanner

// const query = graphql`
//   query TopBanner {
//     allContentfulSiteBanner {
//       edges {
//         node {
//           id
//           image {
//             fluid(quality: 100, cropFocus: CENTER, maxWidth: 1200, maxHeight: 600) {
//               ...GatsbyContentfulFluid
//             }
//             fixed(height: 400, width: 800){
//               ...GatsbyContentfulFixed
//             }
//             file {
//               url
//             }
//           }
//           title
//           url
//           tags
//           hasLink
//           linksToExternal
//         }
//       }
//     }
//   }
// `