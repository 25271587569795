import styled from 'styled-components';

export const StyledImage = styled.div`

  margin-bottom: 4rem;
  
  .buttonNav {
    position: absolute;
    bottom: -4rem;
  }

  .right {
    left: 6rem;
  }

  .loader {
    border: 5px solid ${({ theme }) => theme.primaryLight}; /* Light grey */
    border-top: 5px solid ${({ theme }) => theme.primaryDark}; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: 1rem auto;
  }

  .image-gallery-thumbnail-image {
    height: 50px;
    object-fit: cover;
  }

  .image-gallery-thumbnails-container button {
    margin: 1px;
  }

  img.image-gallery-image {
    object-fit: cover;
  }
  
`