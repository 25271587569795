import styled from 'styled-components';

export const StyledSearch = styled.div`

  .ais-ToggleRefinement {
    display: none;
  }

  .primarySearch {
    max-width: 900px;
    background-color: ${({ theme }) => theme.primaryMediumLight};
    padding: 2rem;
    margin-bottom: 2rem;
  }

  //media

  @media(max-width:576px){
    .primarySearch {
      padding: 1rem;
    }
  }	
  
  @media(min-width: 577px) and (max-width: 768px){
    .primarySearch {
      padding: 1rem;
    }
  }

  .searchBox input {
    width: 100%;
    padding: 0.5rem;
  }

  h1 {
    margin: 1rem 0;
  }

  h3 {
    color: ${({ theme }) => theme.primaryDark};
  }  

  .searchTitle {
    margin-bottom: 3rem;
    margin-top: 0;
  }

  .dealSwitch {
    max-width: 300px;
    margin: 1em auto 1em 0;
  }

  .categoryFilter {
    margin: 2rem 0;
  }

  .resetFilter {
    background: transparent;
    text-align: center;
    border: none;
    margin: auto;
    width: 100%;
    text-decoration: underline;
    font-family: ${({ theme }) => theme.primaryTypefaceBold};
  }

  .resetFilter:disabled {
    color: ${({ theme }) => theme.primaryLight};
    font-weight: normal;
  }

  img {
    margin-bottom: 1rem;
  }

  .dealLabel {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    line-height: 28px;
  }

  .react-switch-bg {
    margin: auto;
  }

  #multiSelect {
    font-family: ${({theme}) => theme.secondaryTypeface};
  }

  .loader {
    border: 5px solid ${({ theme }) => theme.primaryLight}; /* Light grey */
    border-top: 5px solid ${({ theme }) => theme.primaryDark}; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: 1rem auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  ul.ais-RefinementList-list {
    padding: 0;
    display: grid;
    grid-template-columns: 48% 48%;
  }

  li.ais-RefinementList-item {
    list-style: none;
  }

  span.ais-RefinementList-count {
    display: none;
  }

  //Buttons

  .mainSearchBtn {
    width: 100%;
    background-color: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryWhite};
    font-family: ${({ theme }) => theme.primaryTypefaceBold};
  }

  .ais-ClearRefinements-button {
    background-color: transparent;
    text-decoration: underline;
    margin: 0 auto;
  }
  
  .mobileRefine {
    margin: 1rem 0;
  }

  //Custom checkbox

  .input-title {
    display: inline-block;
    margin-left: 1rem;
    width: 70%;
    vertical-align: super;
  }

  .checkbox-label {
    display: inline-block;
    position: relative;
    margin: auto;
    vertical-align: top;
    cursor: pointer;
    clear: both;
  }

.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label .checkbox-custom {
    display: inline-block;
    top: 0px;
    left: 0px;
    height: 24px;
    width: 24px;
    background-color: ${({ theme }) => theme.primaryWhite};
    border-radius: 5px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 2px solid #FFFFFF;
}


.checkbox-label input:checked ~ .checkbox-custom {
    background-color: #FFFFFF;
    border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity:1;
    border: 2px solid #FFFFFF;
}


.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid #009BFF;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity:1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}


.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity:1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #009BFF;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}



/* For Ripple Effect */
.checkbox-label .checkbox-custom::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 0px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid #FFFFFF;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);    
}

.checkbox-label input:checked ~ .checkbox-custom::before {
    left: -3px;
    top: -3px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    transform: scale(3);
    opacity:0;
    z-index: 999;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}


//Media queries

@media(max-width:786px){
  .primarySearch.false {
    opacity: 0;
    padding: 0;
    height: 0;
    overflow: hidden;
  }
  .primarySearch.true {
    transition: opacity 1s ease-in;
    opacity: 1;
    height: auto;
  }
  .mobileRefine.true {
    display: none;
  }
}

@media(min-width: 577px) and (max-width: 768px){
}

@media(min-width: 769px) and (max-width:992px) {
  .mobileRefine {
    display: none;
  }
}

@media(min-width: 993px){
  .mobileRefine {
    display: none;
  }
}


`;
