import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEOPage = ({ entity, title, keywords, description, url, image, type, body, tag, datePublished, dateModified, author, social }) => {

  const { site } = useStaticQuery(query);

  const {
    siteLocale,
    siteName,
    siteTitle,
    siteKeywords,
    siteDescription,
    siteUrl,
    siteImage,
    twitterUsername,
  } = site.siteMetadata;

  const SEO = {
    siteLocale: siteLocale,
    siteName: siteName,
    title: title.charAt(0).toUpperCase() + title.slice(1) || siteTitle,
    description: description || siteDescription,
    image: image || siteImage,
    keywords: keywords || siteKeywords,
    url: process.env.GATSBY_WEBSITE_URL + url || siteUrl,

    //content specific
    type: type,
    body: body,
    tag: tag,
    datePublished: datePublished,
    dateModified: dateModified,
    profileUsername: author,
    social: social
  }

  //JSONLD SEO
  var jsonld = {
    __html: `{}`
  }

  //Normal page
  if (entity === "page") {
    jsonld = {
      __html: `{
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "${SEO.title}",
        "description": "${SEO.description}",
        "url": "${SEO.url}",
        "publisher": {
          "@type": "Organization",
          "name": "${process.env.GATSBY_COMPANY_NAME}",
          "logo":{
            "@type": "ImageObject",
            "url": "${process.env.GATSBY_COMPANY_LOGO_SQUARE}"
          }
        }
      }`
    }
  }

  //Person
  if (entity === "person") {
    jsonld = {
      __html: `{
        "@context": "http://www.schema.org",
        "@type": "Person",
        "@id": "${SEO.url}",
        "name": "${SEO.title}",
        "Description": "${SEO.description}",
        "jobTitle": "${SEO.tag}",
        "worksFor": [
          {
            "@type": "Organization",
            "name": "${process.env.GATSBY_COMPANY_NAME}",
            "sameAs": [
              "${process.env.GATSBY_FACEBOOK_PAGE}",
              "${process.env.GATSBY_TWITTER_PAGE}",
              "${process.env.GATSBY_LINKEDIN_PAGE}",
              "${process.env.GATSBY_INSTAGRAM_PAGE}"
            ]
          }
        ],
        "url": "${SEO.url}",
        "image": "${SEO.image}",
        "sameAs": [
            "${SEO.social.linkedIn}",
            "${SEO.social.instagram}",
            "${SEO.social.facebook}"
          ]
        }
      }`
    }
  }

  //Blog post
  if (entity === "blog") {
    jsonld = {
      __html: `{ 
        "@context": "https://schema.org", 
        "@type": "BlogPosting",
        "headline": "${SEO.title}",
        "image": "${SEO.image}", 
        "keywords": "${SEO.keywords}", 
        "url": "${SEO.url}",
        "datePublished": "${SEO.datePublished}",
        "dateModified": "${SEO.dateModified}",
        "description": "${SEO.description}",
        "articleBody": "${SEO.body}",
        "author": {
          "@type": "Person",
          "name": "${SEO.profileUsername}"
        },
        "publisher": {
          "@type": "Organization",
          "name": "${process.env.GATSBY_COMPANY_NAME}",
          "logo":{
            "@type": "ImageObject",
            "url": "${process.env.GATSBY_COMPANY_LOGO_SQUARE}"
          }
        },
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "${process.env.GATSBY_WEBSITE_URL}"
        }
      }`
    }
  }

  return (

    <Fragment>

      <Helmet>

        {SEO.siteLocale && <meta property="og:locale" content={SEO.siteLocale} />}

        {SEO.title && <title>{SEO.title + " | " + SEO.siteName}</title>}

        {SEO.siteName && <meta property="og:site_name" content={SEO.siteName} />}

        {SEO.title && <meta property="og:title" content={SEO.title + " | " + SEO.siteName} />}
        {SEO.title && <meta name="twitter:title" content={SEO.title + " | " + SEO.siteName} />}

        {SEO.description && <meta name="description" content={SEO.description} />}
        {SEO.description && <meta property="og:description" content={SEO.description} />}
        {SEO.description && <meta name="twitter:description" content={SEO.description} />}

        {SEO.keywords && <meta name="keywords" content={SEO.keywords} />}

        {SEO.image && <meta name="image" content={SEO.image} />}
        {SEO.image && <meta property="og:image" content={SEO.image} />}
        {SEO.image && <meta name="twitter:image" content={SEO.image} />}

        {SEO.image && <meta name="twitter:card" content="summary_large_image" />}

        {SEO.url && <meta property="og:url" content={SEO.url} />}

        {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}

        {/* Content specific */}
        {SEO.type && <meta property="og:type" content={SEO.type} />}
        {SEO.tag && <meta property="og:article:tag" content={SEO.tag} />}
        {SEO.publishDate && <meta property="og:article:published_time" content={SEO.publishDate} />}
        {SEO.updatedAt && <meta property="article:modified_time" content={SEO.updatedAt} />}

        {SEO.profileUsername && <meta property="og:profile:username" content={SEO.profileUsername} />}

      </Helmet>

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={jsonld}
      />

    </Fragment>

  )
}

export default SEOPage;

SEOPage.propTypes = {
  entity: PropTypes.string,
  title: PropTypes.string,
  keywords: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
  tag: PropTypes.string,
  body: PropTypes.string,
  datePublished: PropTypes.string,
  dateModified: PropTypes.string,
  author: PropTypes.string,
  social: PropTypes.array
}

const query = graphql`
  query SEOPage {
    site {
      siteMetadata {
        siteLocale
        siteName
        siteTitle
        siteKeywords
        siteDescription
        siteUrl
        siteImage
        twitterUsername
      }
    }
  }
`