import styled from 'styled-components';

export const StyledMenuSide = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.primaryWhite};
  height: 100vh;
  min-width: 15vw;
  text-align: right;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  z-index: 99999;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }

  li {
    list-style: none;
  }

  a {
    transition: color 0.3s linear;
    
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 0.8rem;
      text-align: center;
    }
  }
`;