import styled from 'styled-components';

export const StyledModal = styled.div`

  h3 span {
    color: ${({ theme }) => theme.primaryWhite };
  }

  input, textarea {
    width: 100%;
    background-color: ${({ theme }) => theme.primaryWhite };
  }

  .modal-main {
    padding: 1rem;
    position:fixed;
    background: ${({ theme }) => theme.primaryDark };
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }

  .display-block {
    display: block;
    z-index: 999;
    position: fixed;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  .display-none {
    display: none;
  }

  .modalClose {
    text-align: right;
  }
`