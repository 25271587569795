import styled from 'styled-components';

export const StyledServices = styled.div`

  h3 {
    color: ${({ theme }) => theme.primaryLight};
  }  


`;
