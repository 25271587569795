import React, {useState, useRef} from "react";
import {useStaticQuery, graphql, Link} from "gatsby";

import {theme} from '../components/css/theme';
import {Burger, Menu} from '../components';
import {StyledHeader} from "./css";

import {useOnClickOutside, useOnClickOutsideInner} from '../hooks';

function Nav() {

  //Mobile menu
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  var openCSS = open ? ' is-active' : '';

  //Inner menu
  const [openInner, setOpenInner] = useState(false);
  const nodeInner = useRef();
  useOnClickOutsideInner(nodeInner, () => setOpenInner(false));

  var openCSSInner = openInner ? ' is-visible' : '';
  var openCSSInnerBtn = openInner ? ' is-active' : '';

  //Querying site metadata
  const {site} = useStaticQuery(query)

  return (

    <StyledHeader>

      {/* {
        theme.navStyle === 'side' && (

          <div ref={node}>

            <Burger open={open} setOpen={setOpen} />
            <Menu data={site.siteMetadata} open={open} setOpen={setOpen} />

          </div>

        )
      } */}

      {
        theme.navStyle === 'horizontal' && (

          // <div>

          //   <Menu data={site.siteMetadata} open={true} />

          // </div>

          <div className="navbar">
            <div className="navbar__primary">
              <div className="navbar__primary__container" ref={node}>
                <div className="navbar__primary__logo navbar__primary__logo--desktop">
                  <a href="https://waterfallcity.co.za/"><img src="https://waterfallcity.co.za/wp-content/uploads/Layer_1-2.svg"
                    alt="Waterfall Logo" className="" /></a>
                </div>
                <div className="navbar__primary__logo navbar__primary__logo--mobile">
                  <a href="https://waterfallcity.co.za/"><img src="https://waterfallcity.co.za/wp-content/uploads/Layer-1.svg"
                    alt="Waterfall Logo" className="" /></a>
                </div>
                <div className={`navbar__primary__menu ${openCSS}`}>

                  <div
                    className="navbar__primary__menu__item navbar__primary__menu__item--has-megamenu">
                    <a href="https://waterfallcity.co.za/play" className="navbar__primary__menu__item__link">
                      Play
                      <span className="navbar__primary__menu__item__link__arrow">
                        <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="path-1-inside-1_597_314138" fill="white">
                            <path d="M0.0361329 0L4.53613 4L9.03613 1.07324e-07"></path>
                          </mask>
                          <path
                            d="M1.03268 -1.12111L-0.0884353 -2.11766L-2.08153 0.124568L-0.960413 1.12111L1.03268 -1.12111ZM4.53613 4L3.53959 5.12111L4.53613 6.00693L5.53268 5.12111L4.53613 4ZM10.0327 1.12111L11.1538 0.124568L9.1607 -2.11766L8.03959 -1.12111L10.0327 1.12111ZM-0.960413 1.12111L3.53959 5.12111L5.53268 2.87889L1.03268 -1.12111L-0.960413 1.12111ZM5.53268 5.12111L10.0327 1.12111L8.03959 -1.12111L3.53959 2.87889L5.53268 5.12111Z"
                            fill="#00325A" mask="url(#path-1-inside-1_597_314138)"></path>
                        </svg>
                      </span>
                    </a>
                    <div className="navbar-megamenu">
                      <div className="container">
                        <div className="navbar-megamenu-sections">
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-1">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/play/eat-your-heart-out/" target="_self">
                                EAT YOUR HEART OUT <svg xmlns="http://www.w3.org/2000/svg" height="11.414"
                                  viewBox="0 0 7.121 11.414" width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-columns navbar-megamenu-columns--count-1">
                              <ul className="navbar-megamenu__links">
                                <li><a href="https://waterfallcity.co.za/play/eat-your-heart-out/" target="_self">SEARCH OUR
                                  RESTAURANTS</a></li>
                              </ul>
                            </div>
                          </div>
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-6">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/play/venture-outside/" target="_self">
                                VENTURE OUTSIDE <svg xmlns="http://www.w3.org/2000/svg" height="11.414"
                                  viewBox="0 0 7.121 11.414" width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-columns navbar-megamenu-columns--count-2">
                              <ul className="navbar-megamenu__links">
                                <li><a href="https://waterfallcity.co.za/play/venture-outside/#mini-fun-fair"
                                  target="_self">MINI FUN FAIR</a></li>
                                <li><a href="https://waterfallcity.co.za/play/venture-outside/#waterfall-park"
                                  target="_self">WATERFALL CITY PARK</a></li>
                                <li><a href="https://waterfallcity.co.za/play/venture-outside/#waterfall-park-run"
                                  target="_self">WATERFALL PARKRUN</a>
                                </li>
                                <li><a href="https://waterfallcity.co.za/play/venture-outside/#waterfall-market"
                                  target="_self">MARKETS AND FESTIVALS</a>
                                </li>
                                <li><a href="https://waterfallcity.co.za/play/venture-outside/#world-of-golf"
                                  target="_self">WORLD OF GOLF</a></li>
                                <li><a href="https://waterfallcity.co.za/play/venture-outside/#selfies"
                                  target="_self">#SELFIES</a></li>
                                <li><a href="https://waterfallcity.co.za/play/venture-outside/#bmw-lifestyle" target="_self">BMW
                                  XDRIVE PARK</a></li>
                                <li><a href="https://waterfallcity.co.za/play/venture-outside/#dancing-shongololo"
                                  target="_self">DANCING SHONGOLOLO</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-1">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/play/play-indoors/" target="_self">
                                PLAY INDOORS <svg xmlns="http://www.w3.org/2000/svg" height="11.414" viewBox="0 0 7.121 11.414"
                                  width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-columns navbar-megamenu-columns--count-1">
                              <ul className="navbar-megamenu__links">
                                <li><a href="https://waterfallcity.co.za/play/play-indoors/#movie-magic" target="_self">MOVIE
                                  MAGIC</a></li>
                                <li><a href="https://waterfallcity.co.za/play/play-indoors/#feel-the-rush"
                                  target="_self">KYALAMI INDOOR KARTING</a></li>
                                <li><a href="https://waterfallcity.co.za/play/play-indoors/#its-a-jungle-out-there"
                                  target="_self">JUNGLE PLAY PARK</a>
                                </li>
                                <li><a href="https://waterfallcity.co.za/play/play-indoors/#get-crafty" target="_self">CREATE
                                  SHOP PLAY</a></li>
                              </ul>
                            </div>
                          </div>
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-4">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/map/?layer=a121cfc6-f15a-4442-b5b3-e76f24c13e6d"
                                target="_self">
                                EXPLORE ON MAP <svg xmlns="http://www.w3.org/2000/svg" height="11.414"
                                  viewBox="0 0 7.121 11.414" width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-images">
                              <div className="navbar-megamenu-image">
                                <div className="navbar-megamenu__image-btn">
                                  <a href="https://waterfallcity.co.za/map/?layer=a121cfc6-f15a-4442-b5b3-e76f24c13e6d"
                                    target="_self">
                                    <img src="https://waterfallcity.co.za/wp-content/uploads/play-map-icon2x.png" alt="" />
                                    <span>VIEW ON MAP</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="navbar__primary__menu__item navbar__primary__menu__item--has-megamenu">
                    <a href="https://waterfallcity.co.za/shop" className="navbar__primary__menu__item__link">
                      Shop
                      <span className="navbar__primary__menu__item__link__arrow">
                        <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="path-1-inside-1_597_314138" fill="white">
                            <path d="M0.0361329 0L4.53613 4L9.03613 1.07324e-07"></path>
                          </mask>
                          <path
                            d="M1.03268 -1.12111L-0.0884353 -2.11766L-2.08153 0.124568L-0.960413 1.12111L1.03268 -1.12111ZM4.53613 4L3.53959 5.12111L4.53613 6.00693L5.53268 5.12111L4.53613 4ZM10.0327 1.12111L11.1538 0.124568L9.1607 -2.11766L8.03959 -1.12111L10.0327 1.12111ZM-0.960413 1.12111L3.53959 5.12111L5.53268 2.87889L1.03268 -1.12111L-0.960413 1.12111ZM5.53268 5.12111L10.0327 1.12111L8.03959 -1.12111L3.53959 2.87889L5.53268 5.12111Z"
                            fill="#00325A" mask="url(#path-1-inside-1_597_314138)"></path>
                        </svg>
                      </span>
                    </a>
                    <div className="navbar-megamenu">
                      <div className="container">
                        <div className="navbar-megamenu-sections">
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-8">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/shop/" target="_self">
                                SHOP 'TIL YOU DROP <svg xmlns="http://www.w3.org/2000/svg" height="11.414"
                                  viewBox="0 0 7.121 11.414" width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-columns navbar-megamenu-columns--count-3">
                              <ul className="navbar-megamenu__links">
                                <li><a href="https://waterfallcity.co.za/shop/mall-of-africa/" target="_self">MALL OF AFRICA</a>
                                </li>
                                <li><a href="https://waterfallcity.co.za/shop/waterfall-corner/" target="_self">WATERFALL
                                  CORNER</a></li>
                                <li><a href="https://waterfallcity.co.za/shop/polofields-crossing/" target="_self">POLOFIELDS
                                  CROSSING</a></li>
                                <li><a href="https://waterfallcity.co.za/shop/waterfall-wilds/" target="_self">WATERFALL
                                  WILDS</a></li>
                                <li><a href="https://waterfallcity.co.za/shop/woodmead-retail-park/" target="_self">WOODMEAD
                                  RETAIL PARK</a></li>
                                <li><a href="https://waterfallcity.co.za/shop/woodmead-value-mart-retail-park/"
                                  target="_self">WOODMEAD VALUE MART</a></li>
                                <li><a href="https://waterfallcity.co.za/shop/makro-woodmead/" target="_self">MAKRO WOODMEAD</a>
                                </li>
                                <li><a href="https://waterfallcity.co.za/shop/hirschs-waterfall/" target="_self">HIRSCH’S
                                  WATERFALL</a></li>
                              </ul>
                            </div>
                          </div>
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-4">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/map/?layer=000934f7-e6ce-4054-9625-5e13945736fa"
                                target="_self">
                                EXPLORE <svg xmlns="http://www.w3.org/2000/svg" height="11.414" viewBox="0 0 7.121 11.414"
                                  width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-images">
                              <div className="navbar-megamenu-image">
                                <div className="navbar-megamenu__image-btn">
                                  <a href="https://waterfallcity.co.za/map/?layer=000934f7-e6ce-4054-9625-5e13945736fa"
                                    target="_self">
                                    <img src="https://waterfallcity.co.za/wp-content/uploads/shop-map-icon2x.png" alt="" />
                                    <span>EXPLORE ON MAP</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="navbar__primary__menu__item navbar__primary__menu__item--has-megamenu">
                    <a href="https://waterfallcity.co.za/work" className="navbar__primary__menu__item__link">
                      Work
                      <span className="navbar__primary__menu__item__link__arrow">
                        <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="path-1-inside-1_597_314138" fill="white">
                            <path d="M0.0361329 0L4.53613 4L9.03613 1.07324e-07"></path>
                          </mask>
                          <path
                            d="M1.03268 -1.12111L-0.0884353 -2.11766L-2.08153 0.124568L-0.960413 1.12111L1.03268 -1.12111ZM4.53613 4L3.53959 5.12111L4.53613 6.00693L5.53268 5.12111L4.53613 4ZM10.0327 1.12111L11.1538 0.124568L9.1607 -2.11766L8.03959 -1.12111L10.0327 1.12111ZM-0.960413 1.12111L3.53959 5.12111L5.53268 2.87889L1.03268 -1.12111L-0.960413 1.12111ZM5.53268 5.12111L10.0327 1.12111L8.03959 -1.12111L3.53959 2.87889L5.53268 5.12111Z"
                            fill="#00325A" mask="url(#path-1-inside-1_597_314138)"></path>
                        </svg>
                      </span>
                    </a>
                    <div className="navbar-megamenu">
                      <div className="container">
                        <div className="navbar-megamenu-sections">
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-4">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/work/#waterfall-commercial-hub" target="_self">
                                WATERFALL CITY COMMERCIAL HUB <svg xmlns="http://www.w3.org/2000/svg" height="11.414"
                                  viewBox="0 0 7.121 11.414" width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-columns navbar-megamenu-columns--count-1">
                              <ul className="navbar-megamenu__links">
                                <li><a href="https://waterfallcity.co.za/work/waterfall-city/" target="_self">WATERFALL CITY
                                  CBD</a></li>
                                <li><a href="https://waterfallcity.co.za/work/waterfall-corporate-campus/"
                                  target="_self">WATERFALL CITY CORPORATE
                                  CAMPUS</a></li>
                                <li><a href="https://waterfallcity.co.za/work/waterfall-point/" target="_self">WATERFALL
                                  POINT</a></li>
                                <li><a href="https://waterfallcity.co.za/work/woodmead-north-office-park/"
                                  target="_self">WOODMEAD NORTH OFFICE PARK</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-4">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/work/#waterfall-logistics-hub" target="_self">
                                WATERFALL CITY LOGISTICS HUB <svg xmlns="http://www.w3.org/2000/svg" height="11.414"
                                  viewBox="0 0 7.121 11.414" width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-columns navbar-megamenu-columns--count-1">
                              <ul className="navbar-megamenu__links">
                                <li><a href="https://waterfallcity.co.za/work/waterfall-distribution-campus/"
                                  target="_self">WATERFALL CITY DISTRIBUTION
                                  CAMPUS</a></li>
                                <li><a href="https://waterfallcity.co.za/work/waterfall-logistics-precinct-north-south/"
                                  target="_self">WATERFALL CITY
                                  LOGISTICS PRECINCT</a></li>
                                <li><a href="https://waterfallcity.co.za/work/waterfall-commercial-district/"
                                  target="_self">WATERFALL CITY COMMERCIAL
                                  DISTRICT</a></li>
                                <li><a href="https://waterfallcity.co.za/work/waterfall-junction/" target="_self">WATERFALL
                                  JUNCTION</a></li>
                              </ul>
                            </div>
                          </div>
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-4">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/work/#waterfall-retail" target="_self">
                                WATERFALL CITY RETAIL <svg xmlns="http://www.w3.org/2000/svg" height="11.414"
                                  viewBox="0 0 7.121 11.414" width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-images">
                              <div className="navbar-megamenu-image">
                                <div className="navbar-megamenu__image-btn">
                                  <a href="https://waterfallcity.co.za/map/?layer=2f9db7b2-c58b-4a26-b923-e3216b221058"
                                    target="_self">
                                    <img src="https://waterfallcity.co.za/wp-content/uploads/work-map-icon2x.png" alt="" />
                                    <span>EXPLORE ON MAP</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="navbar__primary__menu__item navbar__primary__menu__item--has-megamenu">
                    <a href="https://waterfallcity.co.za/stay" className="navbar__primary__menu__item__link">
                      Stay
                      <span className="navbar__primary__menu__item__link__arrow">
                        <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="path-1-inside-1_597_314138" fill="white">
                            <path d="M0.0361329 0L4.53613 4L9.03613 1.07324e-07"></path>
                          </mask>
                          <path
                            d="M1.03268 -1.12111L-0.0884353 -2.11766L-2.08153 0.124568L-0.960413 1.12111L1.03268 -1.12111ZM4.53613 4L3.53959 5.12111L4.53613 6.00693L5.53268 5.12111L4.53613 4ZM10.0327 1.12111L11.1538 0.124568L9.1607 -2.11766L8.03959 -1.12111L10.0327 1.12111ZM-0.960413 1.12111L3.53959 5.12111L5.53268 2.87889L1.03268 -1.12111L-0.960413 1.12111ZM5.53268 5.12111L10.0327 1.12111L8.03959 -1.12111L3.53959 2.87889L5.53268 5.12111Z"
                            fill="#00325A" mask="url(#path-1-inside-1_597_314138)"></path>
                        </svg>
                      </span>
                    </a>
                    <div className="navbar-megamenu">
                      <div className="container">
                        <div className="navbar-megamenu-sections">
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-9">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/stay/" target="_self">
                                RESIDENTIAL DEVELOPMENTS <svg xmlns="http://www.w3.org/2000/svg" height="11.414"
                                  viewBox="0 0 7.121 11.414" width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-columns navbar-megamenu-columns--count-3">
                              <ul className="navbar-megamenu__links">
                                <li><a href="https://waterfallcity.co.za/properties/waterfall-equestrian-estate/"
                                  target="_self">WATERFALL EQUESTRIAN
                                  ESTATE</a></li>
                                <li><a href="https://waterfallcity.co.za/properties/waterfall-country-estate"
                                  target="_self">WATERFALL COUNTRY ESTATE</a>
                                </li>
                                <li><a href="https://waterfallcity.co.za/properties/waterfall-country-village"
                                  target="_self">WATERFALL COUNTRY VILLAGE</a>
                                </li>
                                <li><a href="https://waterfallcity.co.za/properties/ellipse-waterfall" target="_self">ELLIPSE
                                  WATERFALL</a></li>
                                <li><a href="https://waterfallcity.co.za/properties/waterfall-hills-mature-lifestyle-estate"
                                  target="_self">WATERFALL HILLS
                                  MATURE LIFESTYLE ESTATE</a></li>
                                <li><a href="https://waterfallcity.co.za/properties/waterfall-valley-mature-lifestyle-estate/"
                                  target="_self">WATERFALL
                                  VALLEY MATURE LIFESTYLE ESTATE</a></li>
                                <li><a href="https://waterfallcity.co.za/properties/munyaka/" target="_self">MUNYAKA</a></li>
                                <li><a href="https://waterfallcity.co.za/properties/the-polofields-waterfall/"
                                  target="_self">THE POLOFIELDS WATERFALL</a>
                                </li>
                                <li><a href="https://waterfallcity.co.za/properties/kikuyu/" target="_self">KIKUYU WATERFALL</a>
                                </li>
                                <li><a href="https://waterfallcity.co.za/properties/waterfall-crescent/"
                                  target="_self">WATERFALL CRESCENT</a></li>
                                <li><a href="https://waterfallcity.co.za/properties/the-sheds-waterfall/" target="_self">THE
                                  SHEDS @ WATERFALL</a></li>
                                <li><a href="https://waterfallcity.co.za/properties/the-villas-waterfall/" target="_self">THE
                                  VILLAS, WATERFALL</a></li>
                                <li><a href="https://waterfallcity.co.za/properties/waterfall-view/" target="_self">WATERFALL
                                  VIEW</a></li>
                              </ul>
                            </div>
                          </div>
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-3">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/stay/#hotels" target="_self">
                                HOTELS <svg xmlns="http://www.w3.org/2000/svg" height="11.414" viewBox="0 0 7.121 11.414"
                                  width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-images">
                              <div className="navbar-megamenu-image">
                                <div className="navbar-megamenu__image-btn">
                                  <a href="https://waterfallcity.co.za/map/?layer=5ec83c93-4981-4d0b-beb0-2eb967299a63"
                                    target="_self">
                                    <img src="https://waterfallcity.co.za/wp-content/uploads/stay-map-icon2x.png" alt="" />
                                    <span>EXPLORE ON MAP</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="navbar__primary__menu__item navbar__primary__menu__item--toggleable navbar__primary__menu__item--has-megamenu">
                    <a href="https://waterfallcity.co.za/health" className="navbar__primary__menu__item__link">
                      Health
                      <span className="navbar__primary__menu__item__link__arrow">
                        <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="path-1-inside-1_597_314138" fill="white">
                            <path d="M0.0361329 0L4.53613 4L9.03613 1.07324e-07"></path>
                          </mask>
                          <path
                            d="M1.03268 -1.12111L-0.0884353 -2.11766L-2.08153 0.124568L-0.960413 1.12111L1.03268 -1.12111ZM4.53613 4L3.53959 5.12111L4.53613 6.00693L5.53268 5.12111L4.53613 4ZM10.0327 1.12111L11.1538 0.124568L9.1607 -2.11766L8.03959 -1.12111L10.0327 1.12111ZM-0.960413 1.12111L3.53959 5.12111L5.53268 2.87889L1.03268 -1.12111L-0.960413 1.12111ZM5.53268 5.12111L10.0327 1.12111L8.03959 -1.12111L3.53959 2.87889L5.53268 5.12111Z"
                            fill="#00325A" mask="url(#path-1-inside-1_597_314138)"></path>
                        </svg>
                      </span>
                    </a>
                    <div className="navbar-megamenu">
                      <div className="container">
                        <div className="navbar-megamenu-sections">
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-8">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/health/" target="_self">
                                THE PICTURE OF HEALTH <svg xmlns="http://www.w3.org/2000/svg" height="11.414"
                                  viewBox="0 0 7.121 11.414" width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-columns navbar-megamenu-columns--count-3">
                              <ul className="navbar-megamenu__links">
                                <li><a href="https://waterfallcity.co.za/health/the-luna-club-lifestyle/"
                                  target="_self">ELLIPSE</a></li>
                                <li><a href="https://waterfallcity.co.za/health/a-healthy-appetite-for-life/"
                                  target="_self">EQUESTRIAN ESTATE</a></li>
                                <li><a href="https://waterfallcity.co.za/health/living-the-active-life/" target="_self">COUNTRY
                                  ESTATE &amp; COUNTRY
                                  VILLAGE</a></li>
                                <li><a href="https://waterfallcity.co.za/health/right-as-rain/" target="_self">HILLS MATURE
                                  LIFESTYLE ESTATE</a></li>
                                <li><a href="https://waterfallcity.co.za/health/made-for-walking/" target="_self">VALLEY MATURE
                                  LIFESTYLE ESTATE</a></li>
                                <li><a href="https://waterfallcity.co.za/health/get-your-body-beach-ready/"
                                  target="_self">MUNYAKA</a></li>
                                <li><a href="https://waterfallcity.co.za/health/stay-in-shape/" target="_self">POLOFIELDS AND
                                  KIKUYU</a></li>
                                <li><a href="https://waterfallcity.co.za/health/for-the-love-of-running/"
                                  target="_self">WATERFALL CITY ATHLETICS CLUB</a>
                                </li>
                                <li><a href="https://waterfallcity.co.za/health/virgin-active-waterfall/" target="_self">VIRGIN
                                  ACTIVE WATERFALL</a></li>
                                <li><a href="https://waterfallcity.co.za/health/train-smarter/" target="_self">BODYTEC
                                  WATERFALL</a></li>
                                <li><a href="https://waterfallcity.co.za/health/under-the-weather/" target="_self">NETCARE
                                  WATERFALL CITY HOSPITAL</a></li>
                                <li><a href="https://waterfallcity.co.za/health/on-the-back-foot/" target="_self">WATERFALL
                                  HILLS FRAIL CARE CENTRE</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-4">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/map/?layer=6f60d77b-71ec-449f-9687-8c3536b9b4e0"
                                target="_self">
                                EXPLORE <svg xmlns="http://www.w3.org/2000/svg" height="11.414" viewBox="0 0 7.121 11.414"
                                  width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-images">
                              <div className="navbar-megamenu-image">
                                <div className="navbar-megamenu__image-btn">
                                  <a href="https://waterfallcity.co.za/map/?layer=6f60d77b-71ec-449f-9687-8c3536b9b4e0"
                                    target="_self">
                                    <img src="https://waterfallcity.co.za/wp-content/uploads/health-map-icon@2x.png" alt="" />
                                    <span>EXPLORE ON MAP</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="navbar__primary__menu__item navbar__primary__menu__item--toggleable navbar__primary__menu__item--has-megamenu">
                    <a href="https://waterfallcity.co.za/travel" className="navbar__primary__menu__item__link">
                      Travel
                      <span className="navbar__primary__menu__item__link__arrow">
                        <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="path-1-inside-1_597_314138" fill="white">
                            <path d="M0.0361329 0L4.53613 4L9.03613 1.07324e-07"></path>
                          </mask>
                          <path
                            d="M1.03268 -1.12111L-0.0884353 -2.11766L-2.08153 0.124568L-0.960413 1.12111L1.03268 -1.12111ZM4.53613 4L3.53959 5.12111L4.53613 6.00693L5.53268 5.12111L4.53613 4ZM10.0327 1.12111L11.1538 0.124568L9.1607 -2.11766L8.03959 -1.12111L10.0327 1.12111ZM-0.960413 1.12111L3.53959 5.12111L5.53268 2.87889L1.03268 -1.12111L-0.960413 1.12111ZM5.53268 5.12111L10.0327 1.12111L8.03959 -1.12111L3.53959 2.87889L5.53268 5.12111Z"
                            fill="#00325A" mask="url(#path-1-inside-1_597_314138)"></path>
                        </svg>
                      </span>
                    </a>
                    <div className="navbar-megamenu">
                      <div className="container">
                        <div className="navbar-megamenu-sections">
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-8">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/travel/" target="_self">
                                ALL ROADS LEAD TO WATERFALL CITY <svg xmlns="http://www.w3.org/2000/svg" height="11.414"
                                  viewBox="0 0 7.121 11.414" width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-columns navbar-megamenu-columns--count-2">
                              <ul className="navbar-megamenu__links">
                                <li><a href="https://waterfallcity.co.za/travel/#stats" target="_self">HIGHWAYS &amp; BYWAYS</a>
                                </li>
                                <li><a href="https://waterfallcity.co.za/travel/#heliport" target="_self">PLANES, TRAINS,
                                  AUTOMOBILES AND MORE</a></li>
                                <li><a href="https://waterfallcity.co.za/travel/#fuelup" target="_self">FUEL UP</a></li>
                                <li><a href="https://waterfallcity.co.za/travel/#fly" target="_self">FOR THE HIGH FLYERS</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-4">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/map/?layer=d0d3c29a-2eea-4618-be22-cd16bfd6626d"
                                target="_self">
                                EXPLORE <svg xmlns="http://www.w3.org/2000/svg" height="11.414" viewBox="0 0 7.121 11.414"
                                  width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-images">
                              <div className="navbar-megamenu-image">
                                <div className="navbar-megamenu__image-btn">
                                  <a href="https://waterfallcity.co.za/map/?layer=d0d3c29a-2eea-4618-be22-cd16bfd6626d"
                                    target="_self">
                                    <img src="https://waterfallcity.co.za/wp-content/uploads/travel-map-icon2x.png" alt="" />
                                    <span>EXPLORE ON MAP</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="navbar__primary__menu__item navbar__primary__menu__item--toggleable navbar__primary__menu__item--has-megamenu">
                    <a href="https://waterfallcity.co.za/learn" className="navbar__primary__menu__item__link">
                      Learn
                      <span className="navbar__primary__menu__item__link__arrow">
                        <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="path-1-inside-1_597_314138" fill="white">
                            <path d="M0.0361329 0L4.53613 4L9.03613 1.07324e-07"></path>
                          </mask>
                          <path
                            d="M1.03268 -1.12111L-0.0884353 -2.11766L-2.08153 0.124568L-0.960413 1.12111L1.03268 -1.12111ZM4.53613 4L3.53959 5.12111L4.53613 6.00693L5.53268 5.12111L4.53613 4ZM10.0327 1.12111L11.1538 0.124568L9.1607 -2.11766L8.03959 -1.12111L10.0327 1.12111ZM-0.960413 1.12111L3.53959 5.12111L5.53268 2.87889L1.03268 -1.12111L-0.960413 1.12111ZM5.53268 5.12111L10.0327 1.12111L8.03959 -1.12111L3.53959 2.87889L5.53268 5.12111Z"
                            fill="#00325A" mask="url(#path-1-inside-1_597_314138)"></path>
                        </svg>
                      </span>
                    </a>
                    <div className="navbar-megamenu">
                      <div className="container">
                        <div className="navbar-megamenu-sections">
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-8">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/learn/" target="_self">
                                SCHOOLS IN WATERFALL CITY <svg xmlns="http://www.w3.org/2000/svg" height="11.414"
                                  viewBox="0 0 7.121 11.414" width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-columns navbar-megamenu-columns--count-2">
                              <ul className="navbar-megamenu__links">
                                <li><a href="https://waterfallcity.co.za/learn/littlehill-montessori-waterfall/"
                                  target="_self">LITTLEHILL MONTESSORI
                                  WATERFALL CITY</a></li>
                                <li><a href="https://waterfallcity.co.za/learn/reddam-house/" target="_self">REDDAM HOUSE
                                  WATERFALL</a></li>
                                <li><a href="https://waterfallcity.co.za/learn/curro-waterfall/" target="_self">CURRO
                                  WATERFALL</a></li>
                                <li><a href="https://waterfallcity.co.za/learn/stadio-waterfall/" target="_self">STADIO
                                  WATERFALL</a></li>
                              </ul>
                            </div>
                          </div>
                          <div className="navbar-megamenu-section navbar-megamenu-section--wide-4">

                            <div className="navbar-megamenu__heading">
                              <a href="https://waterfallcity.co.za/map/?layer=f9fdb936-2d56-43d0-a5e8-34fe5fe9a260"
                                target="_self">
                                EXPLORE <svg xmlns="http://www.w3.org/2000/svg" height="11.414" viewBox="0 0 7.121 11.414"
                                  width="7.121">
                                  <path d="M5,0,0,5l5,5" data-name="Path 23818" fill="none" stroke="#fff" strokeWidth="2"
                                    transform="translate(5.707 10.707) rotate(180)"></path>
                                </svg> </a>
                            </div>
                            <div className="navbar-megamenu-images">
                              <div className="navbar-megamenu-image">
                                <div className="navbar-megamenu__image-btn">
                                  <a href="https://waterfallcity.co.za/map/?layer=f9fdb936-2d56-43d0-a5e8-34fe5fe9a260"
                                    target="_self">
                                    <img src="https://waterfallcity.co.za/wp-content/uploads/learn-map-icon2x.png" alt="" />
                                    <span>EXPLORE ON MAP</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="navbar__primary__menu__toggler">
                    <button className="navbar__primary__menu__toggler__btn">
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </div> */}


                  <div className="navbar__primary__menu__button">
                    <a href="https://vacancies.waterfall.co.za/" className="navbar__primary__menu__button__link">
                      Lease Space Now </a>
                  </div>

                  <div className="navbar__primary__menu__button">
                    <a href="https://waterfallcity.co.za/move-to-waterfall/#move-stay"
                      className="navbar__primary__menu__button__link">
                      Live in Waterfall city </a>
                  </div>
                </div>

                <div className={`navbar__primary__toggler`}>
                <a href="#" onClick={
                    (e) => {
                      e.preventDefault();
                      setOpen(!open);
                    }
                  }>
                  <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.6748 9.7666H-0.000195622" stroke="#0A2647" strokeWidth="1.5"></path>
                    <path d="M15.6001 5.43311L9.72748e-05 5.43311" stroke="#0A2647" strokeWidth="1.5"></path>
                    <path d="M19.5 1.1001L-4.76837e-07 1.1001" stroke="#0A2647" strokeWidth="1.5"></path>
                  </svg>
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="navbar__secondary">

              <div className="navbar__secondary__container">
                <div className="navbar__secondary__item navbar__secondary__item--search">
                  <form action="/" className="navbar__secondary__search">
                    <div className="navbar__secondary__search__input--wrap">
                      <input type="text" name="s" placeholder="Search" className="navbar__secondary__search__input" />
                      <span className="navbar__secondary__search__input__focus"></span>
                    </div>
                    <button type="submit" className="navbar__secondary__search__btn">
                      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.68322 8.68L12.9282 13M0.928223 5.5C0.928223 6.39002 1.19214 7.26004 1.68661 8.00006C2.18108 8.74009 2.88388 9.31686 3.70615 9.65746C4.52841 9.99805 5.43321 10.0872 6.30613 9.91353C7.17904 9.7399 7.98087 9.31132 8.6102 8.68198C9.23954 8.05264 9.66812 7.25082 9.84176 6.37791C10.0154 5.50499 9.92628 4.60019 9.58568 3.77792C9.24509 2.95566 8.66831 2.25285 7.92829 1.75839C7.18827 1.26392 6.31824 1 5.42822 1C4.23475 1 3.09016 1.47411 2.24624 2.31802C1.40233 3.16193 0.928223 4.30653 0.928223 5.5Z"
                          stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"></path>
                      </svg>
                    </button>
                  </form>
                </div>
                <div className="navbar__secondary__item">
                  <a href="https://waterfallcity.co.za/" className="navbar__secondary__item__link" map-btn="">
                    <span className="navbar__secondary__item__link--map">
                      Map
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.9282 9.00008H19.2582C19.4979 8.997 19.7308 9.08012 19.9143 9.23429C20.0978 9.38847 20.2199 9.60346 20.2582 9.84008L21.9282 19.8401C21.9516 19.9842 21.9432 20.1316 21.9035 20.272C21.8638 20.4125 21.7938 20.5426 21.6985 20.6531C21.6033 20.7637 21.4849 20.852 21.3518 20.9119C21.2188 20.9719 21.0742 21.002 20.9282 21.0001H4.92824C4.7823 21.002 4.63772 20.9719 4.50465 20.9119C4.37157 20.852 4.25323 20.7637 4.15793 20.6531C4.06264 20.5426 3.99269 20.4125 3.95301 20.272C3.91333 20.1316 3.90488 19.9842 3.92824 19.8401L5.59824 9.84008C5.63657 9.60346 5.75863 9.38847 5.94218 9.23429C6.12573 9.08012 6.35855 8.997 6.59824 9.00008H8.92824"
                          stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path
                          d="M16.9282 7C16.9282 5.93913 16.5068 4.92172 15.7566 4.17157C15.0065 3.42143 13.9891 3 12.9282 3C11.8674 3 10.8499 3.42143 10.0998 4.17157C9.34965 4.92172 8.92822 5.93913 8.92822 7C8.92822 11 12.9282 15 12.9282 15C12.9282 15 16.9282 11 16.9282 7Z"
                          stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                      </svg>
                    </span>
                  </a>
                </div>
                <div className="navbar__secondary__item">
                  <a href="https://waterfallcity.co.za/360/WFC360/" className="navbar__secondary__item__link">
                    <span className="navbar__secondary__item__link--360">
                      <svg width="40" height="26" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.7072 18.5788C14.7072 17.8209 14.7072 17.1827 14.7072 16.478C16.7607 17.668 18.768 18.8314 20.8281 20.0281C18.7812 21.2115 16.7739 22.3816 14.7006 23.5849C14.7006 23.0132 14.7006 22.5013 14.7006 21.9827C14.7006 21.2647 14.7006 21.2713 13.9875 21.1716C11.3661 20.786 8.80418 20.1611 6.41393 18.971C5.46971 18.499 4.59152 17.9406 3.87841 17.1494C3.19831 16.3849 2.73611 15.514 3.00683 14.4569C3.11247 14.0181 3.33037 13.5727 3.6209 13.227C4.58492 12.0702 5.94512 11.5649 7.35154 11.0796C7.35154 11.2923 7.33833 11.4585 7.35154 11.6247C7.37135 11.8175 7.28551 11.904 7.12044 12.0037C6.51297 12.376 5.8989 12.7483 5.32445 13.1738C5.04052 13.3865 4.80282 13.6857 4.60473 13.9849C4.20856 14.5699 4.24157 15.1483 4.7566 15.6403C5.15277 16.0126 5.60837 16.345 6.09038 16.5976C7.32513 17.2492 8.66552 17.6215 10.0323 17.8608C11.5576 18.1267 13.1027 18.3328 14.7072 18.5788Z"
                          fill="currentColor"></path>
                        <path
                          d="M19.9696 7.4228C19.963 7.34303 19.9564 7.30314 19.9564 7.2566C19.93 6.28596 19.2433 5.84052 18.3387 6.19953C18.0152 6.32585 17.8303 6.58513 17.8171 6.90424C17.7841 7.6289 17.8105 8.35356 17.8105 9.12475C18.4443 8.28708 19.2961 8.194 20.2007 8.32696C21.2044 8.47987 21.8052 9.11811 21.8646 10.1353C21.9109 10.9863 21.9109 11.8439 21.8713 12.6949C21.825 13.6987 21.1647 14.4168 20.0885 14.696C19.2565 14.9154 18.4113 14.9154 17.5728 14.696C16.404 14.3902 15.7834 13.6057 15.7702 12.389C15.7504 10.6738 15.7504 8.95855 15.7702 7.23665C15.7834 5.84052 16.4503 5.06933 17.8171 4.8034C18.6358 4.64384 19.4612 4.65714 20.2667 4.90977C21.3826 5.26213 21.9967 6.24607 21.8316 7.41616C21.211 7.4228 20.6035 7.4228 19.9696 7.4228ZM17.7708 11.5514C17.7708 11.8372 17.7708 12.1231 17.7708 12.4156C17.7774 13.1203 18.0944 13.4262 18.8141 13.4328C19.547 13.4395 19.8772 13.127 19.8838 12.4023C19.8904 11.8705 19.8838 11.3386 19.8838 10.8134C19.8838 9.95578 19.4216 9.56354 18.5698 9.70315C18.035 9.78958 17.7841 10.0755 17.7708 10.6273C17.7642 10.9331 17.7708 11.2455 17.7708 11.5514Z"
                          fill="currentColor"></path>
                        <path
                          d="M29.1279 9.78949C29.1279 10.6006 29.1279 11.405 29.1279 12.2161C29.1279 13.6588 28.4874 14.4632 27.081 14.7557C26.2952 14.9153 25.5029 14.902 24.7303 14.6759C23.6012 14.3435 23.0136 13.5657 23.007 12.3823C23.0004 10.7136 23.0004 9.03824 23.007 7.36954C23.007 5.89363 23.6607 5.08254 25.1067 4.80332C25.8924 4.65041 26.6848 4.67035 27.4573 4.90304C28.5468 5.23545 29.1279 6.02659 29.1345 7.17009C29.1279 8.04101 29.1279 8.91858 29.1279 9.78949ZM25.0142 9.76955C25.0142 10.6804 25.0142 11.5845 25.0142 12.4953C25.0142 12.8477 25.1199 13.1535 25.45 13.3264C26.2952 13.7718 27.114 13.2865 27.1206 12.3358C27.1272 11.1524 27.1206 9.969 27.1206 8.77896C27.1206 8.18062 27.1404 7.58228 27.1008 6.98394C27.0876 6.76455 26.9819 6.48532 26.8168 6.34571C26.4339 6.0133 25.9519 6.04654 25.5029 6.21274C25.0935 6.36565 25.0076 6.73795 25.0076 7.13685C25.0209 8.02106 25.0142 8.89198 25.0142 9.76955Z"
                          fill="currentColor"></path>
                        <path
                          d="M30.3164 10.7402C31.9077 11.1724 33.4726 11.651 34.7667 12.7347C36.1798 13.9181 36.3118 15.5203 35.1365 16.9497C34.3838 17.8605 33.4065 18.4788 32.3633 18.9973C30.1117 20.1142 27.7082 20.7192 25.2387 21.1048C24.3672 21.2378 23.4956 21.3242 22.6174 21.4173C22.3929 21.4439 22.1618 21.4439 21.9175 21.4505C21.9175 20.5131 21.9175 19.609 21.9175 18.6649C22.6042 18.6184 23.2975 18.5785 23.9776 18.5253C26.4141 18.3259 28.8373 18.0334 31.1682 17.2489C31.9737 16.9763 32.7727 16.6173 33.4924 16.1718C34.8064 15.3541 34.8592 14.3968 33.7301 13.3198C32.8585 12.4887 31.802 11.9768 30.7125 11.5181C30.2239 11.312 30.2239 11.3186 30.3164 10.7402Z"
                          fill="currentColor"></path>
                        <path
                          d="M10.3626 7.72228C9.74188 7.72228 9.14762 7.72228 8.55336 7.72228C8.23642 6.71175 8.78446 5.45523 9.7881 5.03639C10.9106 4.56437 12.0661 4.57766 13.1952 5.02974C13.9677 5.33556 14.3837 5.95385 14.4762 6.78488C14.5092 7.07075 14.5158 7.36327 14.5026 7.64915C14.4696 8.50677 14.08 9.11841 13.2546 9.42423C13.215 9.43752 13.182 9.46412 13.116 9.50401C13.182 9.53725 13.2282 9.56384 13.2744 9.58379C14.0338 9.89625 14.4431 10.468 14.4894 11.2857C14.5158 11.7179 14.529 12.15 14.496 12.5821C14.4035 13.7655 13.7961 14.4636 12.6406 14.7295C11.802 14.9223 10.9568 14.9157 10.1315 14.663C9.17403 14.3639 8.60618 13.719 8.52695 12.6952C8.49393 12.3229 8.52034 11.9506 8.52034 11.565C9.14102 11.565 9.74188 11.565 10.389 11.565C10.389 11.8508 10.3824 12.1234 10.389 12.396C10.4022 13.1206 10.7323 13.4531 11.4454 13.4597C12.1519 13.4597 12.4887 13.1339 12.5085 12.396C12.5151 12.0636 12.5217 11.7312 12.5085 11.3988C12.4821 10.6807 12.1321 10.3284 11.419 10.2819C11.1351 10.2619 10.8512 10.2819 10.5408 10.2819C10.5408 9.80983 10.5408 9.36439 10.5408 8.87907C10.8314 8.87907 11.1153 8.89237 11.3992 8.87907C12.1982 8.84583 12.5151 8.51342 12.5085 7.72228C12.5085 7.40316 12.5085 7.0774 12.4425 6.76493C12.3434 6.30621 12.0001 6.11341 11.4256 6.1267C10.8512 6.14 10.5276 6.35274 10.4418 6.81812C10.3758 7.09734 10.3824 7.39652 10.3626 7.72228Z"
                          fill="currentColor"></path>
                        <path
                          d="M32.119 2.42337C33.3735 2.43002 34.3838 3.44055 34.3838 4.70372C34.3838 5.96688 33.3405 7.01066 32.086 6.99736C30.838 6.98406 29.8344 5.95359 29.841 4.69042C29.8542 3.42061 30.8578 2.41672 32.119 2.42337ZM33.2613 4.71701C33.2679 4.07214 32.7529 3.55357 32.119 3.55357C31.4917 3.55357 30.9833 4.05219 30.9701 4.68377C30.9569 5.32865 31.4587 5.86051 32.0992 5.86716C32.7264 5.87381 33.2547 5.35525 33.2613 4.71701Z"
                          fill="currentColor"></path>
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div> */}
          </div>

        )
      }

    </StyledHeader>

  )
}

export default Nav

const query = graphql`
  query SiteData{
    site {
      siteMetadata {
        siteNavLogo
      }
    }
  }
`