import styled from 'styled-components';

export const StyledPrevNext = styled.div`

  margin-top: 6rem;
  padding: 2rem 0;
  border-top: 3px solid ${({ theme }) => theme.primaryDark};
  border-bottom: 3px solid ${({ theme }) => theme.primaryDark};

  p {
    margin-top: 0;
  }

  .title {
    font-family: ${({ theme }) => theme.secondaryTypefaceBold};
  }

  a {
    color: ${({ theme }) => theme.primaryDark};
  }

`;
